import React from 'react'
import CategoryForm from './CategoryForm'

const AddCategory = () => {
  return (
    <div className='px-4'>
        <CategoryForm />
    </div>
  )
}

export default AddCategory