import { useNavigate } from 'react-router-dom';
import useCreate from '../../useCreate'
import toast from 'react-hot-toast'

const useCreateShipment = ({
    onSuccess
}={}) => {
    const navigate = useNavigate();
    const { setDataToServer } = useCreate({
        url: 'shipping_method_create_and_update',
        refreshUrl: 'airListing',
        onSuccess: (res) => {
            onSuccess?.(res)
            toast.success("Shipment Added Successfully", {
                position: "top-right",
                duration: 3000
            })
            navigate(-1)
        }
    })
    const handlePutToServer = (data) => {
        // console.log(data);
        setDataToServer.mutate(data)
    }

    return { handlePutToServer, setDataToServer }
}

export default useCreateShipment