import Drawer from '@mui/material/Drawer';
import { HiArrowLeft } from "react-icons/hi2";
import CurrencyForm from '../../../components/system/currency/CurrencyForm';

const EditCurrency = ({
    open,
    onClose,
    data
}) => {
    return (
        <>
            <Drawer anchor={'right'} open={open} onClose={onClose}>
                <div className=' w-[600px] bg-white h-full'>
                    <div className='flex items-center space-x-3 border-b py-4 bg-white'>
                        <button className='pl-4' onClick={onClose}>
                            <HiArrowLeft className='w-8 h-8' />
                        </button>
                        <div>
                            <h2 className=' text-2xl font-semibold'>Edit Currency</h2>
                        </div>
                    </div>
                    <div>
                        <CurrencyForm selectedData={data} onCloseForm={onClose} mode='edit'/>
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default EditCurrency