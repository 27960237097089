const Input = (props) => {
    const { className, label, onChange, error, ...InputProps } = props
    return (<div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
                <label className="label" htmlFor={label}>
                    {label}
                </label>
            </div>
            <div className="md:w-2/3">
                <input onChange={onChange} {...InputProps} className={`bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white ${className}`} type="text" />
                {error && <small className="text-red-700 font-semibold">{error.message}</small>}
            </div>
        </div>

    )
}

export default Input;