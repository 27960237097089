import useGetShipListing from "../../../hooks/system/shipments/useGetShipListing"
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Pagination from "../../../components/elements/pagination";
import ShipmentFileManager from "./ShipmentFileManager";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { RiDeleteBinLine } from "react-icons/ri";

const ByShipListing = () => {
  const { shipListing, handlePageClick, handleSearch, handleSetLimit, paramsObject } = useGetShipListing()
  return (
    <div className="mb-20">
      <div className="category_table space-y-5">
        <div className="flex items-end space-x-4">
          <div className="flex-1">
            <input
              type="text"
              placeholder="Search By Code...."
              className="input"
              onChange={(e) => handleSearch(e.target.value)}
              value={paramsObject.searchQuery}
            />
          </div>
          <div>
            <Link to={`/system/create-new-shipment?method=SEA`} className="block">
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                className="primaryBtn-outlined"
              >
                Add New SEA Code
              </Button>
            </Link>
          </div>
          <ShipmentFileManager method={'SEA'} />
          <div>
            <label htmlFor="" className="label">
              Limit
            </label>
            <select name="limit" value={shipListing.data?.per_page} className='input' onChange={(e) => handleSetLimit(e.target.value)}>
              {Array(5).fill(1).map((_, idx) => {
                return <option key={idx} value={(idx + 1) * 10}>{(idx + 1) * 10}</option>
              })}
            </select>
          </div>
        </div>
        <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
          <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
            <thead>
              <tr className="text-left">
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                  Country id
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                  Country code
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                  Weight from
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                  Weight to
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                  Port
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                  Port Code
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                  Charges
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                  Charges type
                </th>
                <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {shipListing?.data?.data?.map((currencyData, idx) => {
                const { id, weight_from, weight_to, country_code, country_id, port, port_code, charges_type, charges } = currencyData || {};
                return (
                  <tr key={idx}>
                    <td className="border-dashed border-t border-gray-200 firstName border-r">
                      <span className="text-gray-700 px-6 py-3 flex items-center text-xs">
                        {country_id}
                      </span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName border-r">
                      <span className="text-gray-700 px-6 py-3 flex justify-center items-center text-xs">
                        {country_code}
                      </span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName border-r">
                      <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-xs">
                        {weight_from}
                      </span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName border-r">
                      <span className="text-gray-700 px-6 py-3 flex items-center text-xs justify-center">
                        {weight_to}
                      </span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName border-r">
                      <span className="text-gray-700 px-6 py-3 flex items-center justify-center text-xs">
                        {port}
                      </span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName border-r">
                      <span className="text-gray-700 px-6 py-3 flex items-center text-xs justify-center">
                        {port_code}
                      </span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName border-r">
                      <span className="text-gray-700 px-6 py-3 flex items-center text-xs justify-center">
                        {charges}
                      </span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 firstName border-r">
                      <span className="text-gray-700 px-6 py-3 flex items-center text-xs justify-center">
                        {charges_type}
                      </span>
                    </td>
                    <td className="border-dashed border-t border-gray-200 emailAddress">
                      <div className="flex items-center justify-center">
                        <Link to={`/system/edit-shipment/${id}?method=SHIP`}>
                          <IconButton>
                            <EditIcon />
                          </IconButton>
                        </Link>
                        {/* <IconButton onClick={() => handleDelete(id)}>
                          <RiDeleteBinLine className="text-lg cursor-pointer text-gray-600" />
                        </IconButton> */}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="mt-5">
          <Pagination
            currentPage={paramsObject?.pageNo}
            lengthofItems={shipListing?.data?.total_count}
            limit={shipListing?.data?.per_page}
            onPageChange={handlePageClick}
          />
        </div>
      </div>
    </div>
  )
}

export default ByShipListing