import toast from 'react-hot-toast'
import useCreate from '../../useCreate'

const useDeleteCurrency = ({
    onSuccess
}) => {
    const { setDataToServer } = useCreate({
        url: 'currency_delete',
        refreshUrl: 'currency_listing',
        onSuccess: (res) => {
            onSuccess?.(res)
            toast.success("Currency Deleted Successfully", {
                position: "top-right",
                duration: 3000
            })
        }
    })
    const handleSubmit = (data) => {
        setDataToServer.mutate(data)
    }

    return {handleSubmit,setDataToServer}
}

export default useDeleteCurrency