import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import useDeleteCurrency from '../../../hooks/system/currency/useDeleteCurrency';
const DeleteCurrency = ({
    open,
    handleClose,
    selectedId
}) => {
    const { handleSubmit: handleDelete, setDataToServer } = useDeleteCurrency({
        onSuccess: () => {
            handleClose()
        }
    })
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={style}>
                <div>
                    <h2 className=' text-lg text-gray-700'>Are you sure you want to delete this Currency?</h2>
                    <div className=' space-x-4 mt-4'>
                        <button disabled={setDataToServer?.isLoading} onClick={() => handleDelete({ id: selectedId })} className=' px-4 py-2 rounded bg-red-600 text-white'>
                            {setDataToServer?.isLoading ? 'Deleting...' : 'Delete'}
                        </button>
                        <button onClick={handleClose} className='px-4 py-2 rounded border text-balck'>Cancel</button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default DeleteCurrency