import useCreate from '../../useCreate'
import toast from 'react-hot-toast'

const usePostCurrencyList = ({
    onSuccess
}) => {
    // const { categoryId } = useParams()
    // const navigate = useNavigate();
    // const [searchParams] = useSearchParams();
    const { setDataToServer } = useCreate({
        url: 'currency_create_and_update',
        refreshUrl: 'currency_listing',
        onSuccess: (res) => {
            // handleResetForm()
            // if (searchParams.get("mode") === 'edit') {
            //     navigate(`/catalog/category`)
            // }else{
            //     reset?.()
            // }
            onSuccess?.(res)
            toast.success("Category Added Successfully", {
                position: "top-right",
                duration: 3000
            })
        }
    })
    const handleSubmit = (data) => {
        // console.log(data);
        setDataToServer.mutate(data)
    }

    return handleSubmit
}

export default usePostCurrencyList