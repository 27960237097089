import useRead from "../../useRead";

let initialData = {
  pageNo: 0,
  limit: 50,
  searchQuery: ''
}
const useGetCurrencyList = () => {
  // currency_listing
  const { data: currencyListing, paramsObject, setGetListParams } = useRead({
    initialData: initialData,
    url: "currency_listing",
    method: "GET",
    onSuccess: (res) => {
      // onSuccess(res)
    }
  });
  const handleSearch = (value) => {
    setGetListParams(prev => {
      return {
        ...prev,
        searchQuery: value
      }
    })
  }
  const handleSetLimit = (value) => {
    setGetListParams(prev => {
      return {
        ...prev,
        limit: value
      }
    })
  }
  const handlePageClick = (val) => {
    let pageNo = {
      ...paramsObject,
      pageNo: val.selected,
    };
    setGetListParams(pageNo);
    // setSearchParams(pageNo);
  };
  return { currencyListing, paramsObject, handleSearch,handleSetLimit,handlePageClick }
}

export default useGetCurrencyList