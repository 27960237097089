import React, {
    useState,
    forwardRef,
    useImperativeHandle,
    useEffect,
} from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../components/elements/buttons";
import Input from "../../../components/elements/inputs/Input";
import SwitchToggle from "../../../components/elements/switchtoggle/SwitchToggle";
import useCreate from "../../../hooks/useCreate";
import CategoryStatusUpdate from "./CategoryStatusUpdate";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { generateSlug } from "../../../utilities";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useParams } from "react-router-dom";
import useCreateCategory from "../../../hooks/category/useCreateCategory";
import useGetCategoryList from "../../../hooks/category/useGetCategoryList";
import CircularProgress from "@mui/material/CircularProgress";
import { Controller, useForm } from "react-hook-form";
import { SLUG_SPECIAL_CHAR } from "../../../config";


const CategoryForm = forwardRef(({ data }, ref) => {
    const [inputValue, setInputValue] = useState('')
    const { categoryId } = useParams();
    const [searchParams] = useSearchParams();
    const mode = searchParams.get("mode");
    const navigate = useNavigate();

    const { control, setValue, handleSubmit, reset, getValues,clearErrors } = useForm({
        defaultValues: {
            status: true,
            name: "",
            slug: "",
            category_code: "",
            category_image: "",
            seo: {
                meta_title: "",
                meta_keywords: "",
                meta_desc: "",
            },
            parent_id: null,
        }
    });

    const handleResetForm = () => {
        reset()
    };
    useImperativeHandle(ref, () => ({
        handleResetForm,
    }));
    const handleForm = (name, value) => {
        if (name === "name") {
            const slugValue = generateSlug(value);
            setValue("slug", slugValue);
        }
    };


    const { handleSubmit: handleCategorySubmit } = useCreateCategory({
        reset: () => reset()
    });
    const { data: list } = useGetCategoryList();

    // const { setDataToServer: getSingleEditData } = useCreate({
    //     url: "fetch_single_category",
    //     onSuccess: (res) => {
    //         let resData = res.data;
    //         Object.keys(resData).forEach((key) => {
    //             setValue(key, resData[key])
    //         })
    //         setInputValue(resData.parent_name)
    //         console.log(resData?.seo?.[0].meta_title);
    //     },
        
    // });
    const { setDataToServer: getSingleEditData } = useCreate({
        url: "fetch_single_category",
        onSuccess: (res) => {
            let resData = res.data;
            Object.keys(resData).forEach((key) => {
                if (key === "seo" && resData.seo && resData.seo.length > 0) {
                    setValue("seo.meta_title", resData.seo[0].meta_title);
                    setValue("seo.meta_keywords", resData.seo[0].meta_keywords);
                    setValue("seo.meta_desc", resData.seo[0].meta_desc);
                } else {
                    setValue(key, resData[key]);
                }
            });
            setInputValue(resData.parent_name);
        },
    });
    useEffect(() => {
        if (mode === "edit") {
            getSingleEditData.mutate({
                id: categoryId,
            });
        }
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit(handleCategorySubmit)}>
                <div className="flex items-center justify-between">
                    <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className=" space-x-2 flex items-center"
                    >
                        <ArrowBackIcon className="w-6 h-6" />
                        <span>Back</span>
                    </button>
                    <div className="flex justify-end mt-5 space-x-3">
                        <PrimaryButton label={"Save"} type={"submit"} />
                    </div>
                </div>
                <div className="border-b-2 border-black pt-2 pb-2">
                    <span className="text-2xl font-normal">Currently Active</span>
                </div>
                {mode === "edit" ? (
                    <CategoryStatusUpdate status={getValues('status')} categoryId={getValues('categoryId')} />
                ) : (
                    <div className=" -ml-4 mt-4">
                        <Controller
                            name="status"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <div>
                                    <SwitchToggle
                                        checkedValue={value}
                                        label={"Enable Category"}
                                        onChange={(e) => onChange(e)}
                                        name="status"
                                    />
                                </div>
                            )}
                            rules={{ required: "Name is required" }}
                        />
                        <small className="block text-gray-500 ml-4 italic font-semibold relative -top-2">
                            Store Front
                        </small>
                    </div>
                )}
                <div>
                    <div className="mb-6 mt-6">
                        <Controller
                            name="name"
                            control={control}
                            rules={{ 
                                required: "Category Name is required",
                            }}
                            render={({ field: { value, onChange },fieldState: { error } }) => (
                                <div>
                                    <Input
                                        label={"Category Name"}
                                        placeholder={"e.g. Clothing"}
                                        // required={"required"}
                                        value={value}
                                        error={error}
                                        onChange={(e) => {
                                            onChange(e);
                                            handleForm("name", e.target.value);
                                            clearErrors('slug');
                                        }}
                                    />
                                </div>
                            )}
                        />
                    </div>
                    <div className="mb-6 mt-6">
                        <Controller
                            name="slug"
                            control={control}
                            rules={{ 
                                required: "Slug is required",
                                pattern: { value: /^[A-Za-z0-9-]+$/, message: 'Invalid Slug. Only letters, numbers, and hyphens are allowed.' },
                            }}
                            render={({ field: { value, onChange },fieldState: { error } }) => (
                                <>
                                    <Input
                                        label={"Category Slug"}
                                        // required={"required"}
                                        placeholder={"Category Slug"}
                                        value={value}
                                        onChange={onChange}
                                        error={error}
                                    />
                                </>
                            )}
                        />
                    </div>
                    <div className="mb-6 mt-6">
                        <Controller
                            name="category_code"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <Input
                                    label={"Category Code"}
                                    placeholder={"Category Code"}
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </div>
                    <div>
                        <div className="md:flex md:items-center mb-6">
                            <div className="md:w-1/3">
                                <label className="label" htmlFor={"parent"}>
                                    Parent
                                </label>
                            </div>
                            <div className="md:w-2/3">
                                {mode === "edit" &&
                                    <div className=" flex items-center space-x-2">
                                        <p>Current: </p>
                                        <small className=" text-gray-500">{getValues('parent_name')}</small>
                                    </div>}

                                <Controller
                                    name="parent_id"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <Autocomplete
                                            fullWidth
                                            freeSolo
                                            disablePortal
                                            // value={value}
                                            disabled={mode === "edit" ? true : false}
                                            loading={list.isLoading}
                                            onChange={(event, newValue) => {
                                                onChange(newValue ? newValue?.id : null);
                                            }}
                                            inputValue={inputValue}
                                            onInputChange={(event, newInputValue) => {
                                                console.log(newInputValue);
                                                setInputValue(newInputValue)
                                            }}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) =>
                                                option?.id === value
                                            }
                                            id="combo-box-demo"
                                            className="bg-gray-200 appearance-none rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white"
                                            options={list?.data?.data || []}
                                            renderInput={(params) => (
                                                <TextField
                                                    placeholder="Search parent"
                                                    {...params}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <>
                                                                {list.isLoading ? (
                                                                    <CircularProgress
                                                                        color="secondary"
                                                                        size={20}
                                                                    />
                                                                ) : null}
                                                                {params.InputProps.endAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {mode === "edit" && 
                <div>
                    <div className="mb-6 mt-6">
                        <label htmlFor="Product Manufacturer" className='label'>Image </label>
                        <div className='w-52 h-52 mt-2'>
                            <img src={getSingleEditData?.data?.data?.image_url} alt="product image" className='w-full h-full' />
                        </div>
                    </div>
                </div>}
                <>
                    {/* SEO */}
                    <div className="border-b-2 border-black pt-2 pb-2">
                        <span className="text-2xl font-normal">
                            Search Engine Optimization
                        </span>
                    </div>
                    <div>
                        <div className="pt-6 pb-6  space-y-9">
                            <Controller
                                name="seo.meta_title"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <Input
                                        label={"Meta Title"}
                                        placeholder={"Meta title"}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )}
                            />

                            <Controller
                                name="seo.meta_keywords"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <Input
                                        label={"Meta Keywords"}
                                        placeholder={"meta keywords"}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )}
                            />

                            <Controller
                                name="seo.meta_desc"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <Input
                                        label={"Meta Description"}
                                        placeholder={"meta description"}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </>
            </form>
        </>
    );
});

export default CategoryForm;
