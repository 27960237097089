import useRead from '../useRead';

const useFetchSingleProduct = ({ product_id, onSuccess= () => {} } = {}) => {
    const { data: singleProduct } = useRead({
        initialData: {
            product_id
        },
        url: "fetch_single_product",
        method: "GET",
        onSuccess: (res) => {
            onSuccess(res)
        }
    });
    return singleProduct
}

export default useFetchSingleProduct