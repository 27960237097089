import { useRef } from 'react';
import useDownload from '../../../hooks/useDownload';
import { FiUpload } from "react-icons/fi";
import useUploadfile from '../../../hooks/useUploadfile';
import useDownloadShipment from '../../../hooks/useDownloadShipment';
import toast from 'react-hot-toast';

const ShipmentFileManager = ({method}) => {
    const uploadRef = useRef(null);
    const { downloadFile } = useDownloadShipment({
        url: 'shipping_method_excel_templete_download', // Your download URL
        onSuccess: () => toast.success('File downloaded successfully'),
        onError: (err) => toast.error('Error downloading file')
    });
    const { setDataToServer } = useUploadfile({
        url: 'shipping_method_excel_upload',
        refreshUrl: method === 'SEA' ? 'shipListing' : 'airListing'
        // pass necessary options
    });
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setDataToServer.mutate({ excel_file: file, shipping_method: method }); // Trigger the mutation with the selected file
    };
    return (
        <div className='flex items-center space-x-5'>
            <div class="flex flex-col justify-center items-center underline-offset-1 underline gap-2">
                <button className="flex gap-2 items-center" onClick={() => downloadFile(method)}
                >
                    Download
                </button>
            </div>
            <div class="flex flex-col justify-center items-center underline-offset-1 underline gap-2">
                <button
                    className="flex gap-2 items-center "
                    //   onSubmit={handleSubmit(handleFileUpload)}
                    type="submit"
                    onClick={() => uploadRef.current.click()}
                >
                    <FiUpload />
                    Upload
                </button>
                {/* <label for="fileInput">Upload File</label> */}
                <input
                    ref={uploadRef}
                    onChange={handleFileChange}
                    type="file"
                    accept=".xls, .xlsx"
                    id="fileInput"
                    className="hidden"
                />
            </div>
        </div>
    )
}

export default ShipmentFileManager