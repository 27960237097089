import axios from "axios";
import { API_ENDPOINT, TOKEN_KEY } from "../config"; 

export const getCustomerList = async (queryParams) => { 
    const token = sessionStorage.getItem(TOKEN_KEY);
    
    try {
        const res = await axios.get(`${API_ENDPOINT}customer_listing`, {
            params: queryParams,
            headers: { Authorization: token ? `Bearer ${token}` : '' }
        });
        return res.data;
    } catch (error) {
        // Handle errors
        console.error("Error fetching customer list:", error);
        throw error;
    }
};