import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "../apis/axios";
import { TOKEN_KEY } from "../config";

function useCreate({
  refreshUrl = '',
  url = '',
  onSuccess,
  onError
}) {
  const queryClient = useQueryClient()
  const setDataToServer = useMutation((data) => setUsers(data), {
    onError: (err) => {
      onError?.(err)
    },
    onSuccess: (data) => {
      if (refreshUrl)
        queryClient.invalidateQueries([refreshUrl])
      onSuccess?.(data)
    }
  })
  const setUsers = async (getFormData) => {
    let TOKEN = sessionStorage.getItem(TOKEN_KEY);
    let fd = new FormData()
    for (var key in getFormData) {
      if (Array.isArray(getFormData[key]) || typeof getFormData[key] === 'object') {
        fd.append(key, JSON.stringify(getFormData[key]));
      } else {
        fd.append(key, getFormData[key]);
      }
    }
    const response = await axios({
      method: "POST",
      data: fd,
      url: `/${url}`,
      headers: { Authorization: TOKEN ? `Bearer ${TOKEN}` : '', }
    })
    return response.data
  }
  return { setDataToServer }

}

export default useCreate