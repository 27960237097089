import { Outlet } from "react-router-dom"
import Footer from "../footer"
import Header from "../header"
import SideBar from "../navigation/sidebar"
import './layout.css'

function Layout({ children }) {
    return (
        <>
            <SideBar />
            <div className="layout ml-[100px] -z-[1]">
                <div className="container_xxl">
                    <Outlet />
                </div>
            </div>
            {/* <Footer /> */}
        </>
    )
}

export default Layout