import axios from "axios";
import { API_ENDPOINT } from "../config";


export const handleLogin = async (data) => {
    const { email, password } = data
    let fd = new FormData()
    fd.append('email', email)
    fd.append('password', password)
    let res = await axios({
        method: "POST",
        data: fd,
        url: `${API_ENDPOINT}login`,
        withCredentials: true,
    })
    return res
};