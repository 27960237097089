import useRead from '../../useRead';

let initialData = {
    pageNo: 0,
    limit: 20,
    searchQuery: '',
    screen: 'AIR'
}
const useGetAirListing = () => {
    // currency_listing
    const { data: airListing, paramsObject, setGetListParams } = useRead({
        initialData: initialData,
        url: "shipping_method_listing",
        method: "GET",
        queryName: 'airListing',
        onSuccess: (res) => {
            // onSuccess(res)
        }
    });
    const handleSearch = (value) => {
        setGetListParams(prev => {
            return {
                ...prev,
                searchQuery: value
            }
        })
    }
    const handleSetLimit = (value) => {
        setGetListParams(prev => {
            return {
                ...prev,
                limit: value
            }
        })
    }
    const handlePageClick = (val) => {
        let pageNo = {
            ...paramsObject,
            pageNo: val.selected,
        };
        setGetListParams(pageNo);
        // setSearchParams(pageNo);
    };
    return { airListing, paramsObject, handleSearch, handleSetLimit, handlePageClick }
}

export default useGetAirListing