import axios from "axios";
import { API_ENDPOINT, TOKEN_KEY } from "../config";

export const getOrdersList = async (queryParams) => {
    const token = sessionStorage.getItem(TOKEN_KEY);
    
    try {
        const res = await axios.get(`${API_ENDPOINT}order_listing`, {
            params: queryParams,
            headers: { Authorization: token ? `Bearer ${token}` : '' }
        });
        return res.data;
    } catch (error) {
        // Handle errors
        console.error("Error fetching order list:", error);
        throw error;
    }
};

export const getOrderPdf = async (data) => {
    const token = sessionStorage.getItem(TOKEN_KEY);
    const formData = new FormData();
    for (const key in data) {
        formData.append(key, data[key]);
    }
    try {
        const res = await axios.post(`${API_ENDPOINT}order_view_pdf`, formData, {
            responseType: 'blob',
            headers: { 
                Authorization: token ? `Bearer ${token}` : '', 
            }
        });
        return res;
    } catch (error) {
        // Handle errors
        console.error("Error fetching order PDF:", error);
        throw error;
    }
};
