import './App.css';
import { Route, Routes, BrowserRouter as Router } from "react-router-dom"
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Toaster } from 'react-hot-toast'
import Login from './pages/auth/Login';
import Dashboard from './pages/dashboard';
import Category from './pages/catalog/category';
import Products from './pages/catalog/products';
import Services from './pages/services';
import Orders from './pages/sales/orders';
import Coupons from './pages/customers/coupons';
import ProtectedRoutes from './routes/ProtectedRoutes';
import Reports from './pages/reports';
import Blogs from './pages/blogs';
import AddProduct from './pages/catalog/products/AddProduct';
import Taxes from './pages/taxes';
import Brands from './pages/brands';
import EditProduct from './pages/catalog/products/EditProduct';
import Layout from './components/layout/index'
import SalesRoute from './routes/SalesRoute';
import ScrollTop from './routes/ScrollTop';
import Customers from './pages/customers/customersList';
import "react-datepicker/dist/react-datepicker.css";
import CategoryLayout from './components/layout/CategoryLayout';
import CategoryProvider from './providers/CategoryProvider';
import AddSubCategory from './pages/catalog/category/AddSubCategory';
import AddCategory from './pages/catalog/category/AddCategory';
import EditCategory from './pages/catalog/category/EditCategory';
import AddSubProduct from './pages/catalog/products/SubProduct/AddSubProduct';
import EditSubProduct from './pages/catalog/products/SubProduct/EditSubProduct';
import Currency from './pages/systems/currency';
import Shipping from './pages/systems/shipping/ByShipListing';
import ShipmentLayout from './components/layout/ShipmentLayout';
import ByShipListing from './pages/systems/shipping/ByShipListing';
import ByAirListing from './pages/systems/shipping/ByAirListing';
import CreateShipmentData from './pages/systems/shipping/CreateShipmentData';
import EditShipmentData from './pages/systems/shipping/EditShipmentData';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import ProductUpload from './pages/catalog/productupload';


const queryClient = new QueryClient()
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster />
      <Router>
        <ScrollTop />
        <Routes>
          <Route path='/login' exact element={<Login />} />
          <Route path='/' exact element={<Login />} />
          <Route path='/forgot-password' exact element={<ForgotPassword />} />
          <Route path='/reset-password' exact element={<ResetPassword />} />
          <Route element={<ProtectedRoutes />}>
            <Route element={<Layout />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path='sales/orders' exact element={<Orders />} />
              <Route path='sales/invoices' exact element={<Dashboard />} />
              <Route path='sales/returns' exact element={<Dashboard />} />
              <Route path='catalog/category' exact element={<Category />} />
              <Route path='catalog/category/add-category' exact element={<AddCategory />} />
              <Route path='catalog/category/edit-category/:categoryId' exact element={<EditCategory />} />
              {/* <Route path='catalog/category/parent/:parentId' exact element={<AddSubCategory />} /> */}
              {/* <Route element={<CategoryProvider />}> 
                <Route element={<CategoryLayout />}> 
                </Route>
              </Route> */}
              <Route path='catalog/products' exact element={<Products />} />
              <Route path='/catalog/product-uploads' exact element={<ProductUpload/>} />
              <Route path='catalog/products/add-product' exact element={<AddProduct />} />
              <Route path='catalog/products/edit-product/:id' exact element={<EditProduct />} />
              <Route path='catalog/products/add-sub-product/:parentId/:categoryId' exact element={<AddSubProduct />} />
              <Route path='catalog/products/edit-sub-product/:parentId/:categoryId' exact element={<EditSubProduct />} />
              <Route path='customers/coupons' exact element={<Coupons />} />
              <Route path='customers/all-customers' exact element={<Customers />} />
              <Route path='system/blogs' exact element={<Blogs />} />
              <Route path='system/currency' exact element={<Currency />} />
              <Route element={<ShipmentLayout />}> 
                <Route path='system/shipping' exact element={<ByShipListing />} />
                <Route path='system/shipping-by-air' exact element={<ByAirListing />} />
              </Route>
              <Route path='system/create-new-shipment' exact element={<CreateShipmentData />} />
              <Route path='system/edit-shipment/:shipmentId' exact element={<EditShipmentData />} />
            </Route>
          </Route>
          {/* <Route path='/dashboard' exact element={<ProtectedRoutes><Dashboard /></ProtectedRoutes>} />
          <Route path='/category' exact element={<ProtectedRoutes><Category /></ProtectedRoutes>} />
          <Route path='/products' exact element={<ProtectedRoutes><Products /></ProtectedRoutes>} />
          <Route path='/products/add-product' exact element={<ProtectedRoutes><AddProduct /></ProtectedRoutes>} />
          <Route path='/products/:id' exact element={<ProtectedRoutes><EditProduct /></ProtectedRoutes>} />
          <Route path='/services' exact element={<ProtectedRoutes><Services /></ProtectedRoutes>} />
          <Route path='/orders' exact element={<ProtectedRoutes><Orders /></ProtectedRoutes>} />
          <Route path='/lens' exact element={<ProtectedRoutes><Lens /></ProtectedRoutes>} />
          <Route path='/size' exact element={<ProtectedRoutes><LensSize /></ProtectedRoutes>} />
          <Route path='/lens-size-mapping' exact element={<ProtectedRoutes><LenSizeMapping /></ProtectedRoutes>} />
          <Route path='/customers' exact element={<ProtectedRoutes><Customers /></ProtectedRoutes>} />
          <Route path='/coupons' exact element={<ProtectedRoutes><Coupons /></ProtectedRoutes>} />
          <Route path='/reports' exact element={<ProtectedRoutes><Reports /></ProtectedRoutes>} />
          <Route path='/blogs' exact element={<ProtectedRoutes><Blogs /></ProtectedRoutes>} />
          <Route path='/taxes' exact element={<ProtectedRoutes><Taxes /></ProtectedRoutes>} />
          <Route path='/brands' exact element={<ProtectedRoutes><Brands /></ProtectedRoutes>} />
          <Route path='/frames' exact element={<ProtectedRoutes><Frames /></ProtectedRoutes>} /> */}
        </Routes>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
